import { required, email, max, integer } from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'
import { isKana, isPhoneNumber, isCellPhoneNumber, isPostalCode, isURL } from '@/utils/validate'

extend('required', {
  ...required,
  message: '必須項目です'
})

extend('email', {
  ...email,
  message: '正しいメールアドレスを入力してください'
})

extend('integer', {
  ...integer,
  message: '数字を入力してください'
})

extend('max', {
  ...max,
  message: '{length}文字以内で入力してください'
})

extend('url', {
  message: '正しいURLを入力してください',
  validate(value :string) {
    return isURL(value)
  }
})

extend('kana', {
  message: 'カタカナで入力してください',
  validate(value :string) {
    return isKana(value)
  }
})

extend('postal_code', {
  message: '正しい郵便番号（-なし）を入力してください',
  validate(value :string) {
    return isPostalCode(value)
  }
})

extend('phone_number', {
  message: '正しい電話番号（-なし）を入力してください',
  validate(value :string) {
    return isPhoneNumber(value)
  }
})

extend('cell_phone_number', {
  message: '正しい携帯番号（-なし）を入力してください',
  validate(value :string) {
    return isCellPhoneNumber(value)
  }
})
