import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { ValidationProvider } from 'vee-validate'
import '@/plugins/vee-validate'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

Vue.component('ValidationProvider', ValidationProvider)

const vm = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')

export default vm
