









































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { MedicalBusiness } from '@/interface/api'
import { retrieveMedicalBusiness } from '@/api/medical-business'
import { AlertModule } from '@/store/modules/alert'

@Component({
  name: 'ManagePage',
  components: {
    Header,
    Footer,
  },
})
export default class ManagePage extends Vue {
  @Prop({ default : ''}) private medicalBusinessId! :string
  private medicalbusiness :MedicalBusiness | null = null
  private canFirstReservationForEachDoctor = false
  private canSecondLaterReservationForEachDoctor = false
  private loading = false
  private isErrorAlertOpen = false
  private isWarningAlertOpen = false
  private canFaceToFace = false
  private canOnlineTreatment = false

  private get errorTime() {
    return AlertModule.errorTime
  }

  private get errorAlertMessage() {
    return AlertModule.errorAlertMessage
  }

  private get warningTime() {
    return AlertModule.warningTime
  }

  private get warningAlertMessage() {
    return AlertModule.warningAlertMessage
  }

  @Watch('errorTime')
  private async onErrorTimeChange() {
    this.isErrorAlertOpen = true
  }

  @Watch('warningTime')
  private async onWarningTimeChange() {
    this.isWarningAlertOpen = true
  }

  @Watch('medicalBusinessId', { immediate: true })
  private async onMedicalBusinessIdChange() {
    this.isWarningAlertOpen = false
    if (!this.medicalBusinessId) return
    this.loading = true
    this.medicalbusiness = await retrieveMedicalBusiness(this.medicalBusinessId)
    if (!this.medicalbusiness.can_web_first_treatment_reservation) {
      this.$router.push({ path: '/404' })
      this.loading = false
      return
    }
    this.canFirstReservationForEachDoctor = Boolean(this.medicalbusiness.can_first_reservation_for_each_doctor)
    this.canSecondLaterReservationForEachDoctor = Boolean(this.medicalbusiness.can_second_later_reservation_for_each_doctor)
    this.canFaceToFace = Boolean(this.medicalbusiness.can_face_to_face)
    this.canOnlineTreatment = Boolean(this.medicalbusiness.can_online_treatment)
    this.loading = false
  }
}
