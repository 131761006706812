







































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { RouteName } from '@/router/index'
import { MedicalBusiness } from '@/interface/api'

@Component({})
export default class Header extends Vue {
  @Prop({ default : () => '' }) private medicalbusinessId!: string
  @Prop({ default : () => null }) private medicalbusiness!: MedicalBusiness

  private get routeName() {
    return RouteName
  }

  private menuClick() {
    const target = document.getElementById('menu-btn-check')
    target!.click()
  }
}
