import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/store'

export interface IAlertState {
  errorTime: Date
  errorAlertMessage: string
  warningTime: Date
  warningAlertMessage: string
}

@Module({ dynamic: true, store, name: 'alert' })
class Alert extends VuexModule implements IAlertState {
  public errorTime = new Date()
  public errorAlertMessage = ''
  public warningTime = new Date()
  public warningAlertMessage = ''

  @Mutation
  private ERROR_TIME(errorTime: Date) {
    this.errorTime = errorTime
  }

  @Mutation
  private ERROR_ALERT_MESSAGE(errorAlertMessage: string) {
    this.errorAlertMessage = errorAlertMessage
  }

  @Mutation
  private WARNING_TIME(warningTime: Date) {
    this.warningTime = warningTime
  }

  @Mutation
  private WARNING_ALERT_MESSAGE(warningAlertMessage: string) {
    this.warningAlertMessage = warningAlertMessage
  }

  @Action
  public SetErrorAlert(alert: { errorTime: Date, message: string }) {
    this.ERROR_TIME(alert.errorTime)
    this.ERROR_ALERT_MESSAGE(alert.message)
  }

  @Action
  public SetWarningAlert(alert: { warningTime: Date, message: string }) {
    this.WARNING_TIME(alert.warningTime)
    this.WARNING_ALERT_MESSAGE(alert.message)
  }
}

export const AlertModule = getModule(Alert)
