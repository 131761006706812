import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'
import ManagePage from '@/views/ManagePage.vue'

Vue.use(VueRouter)

export enum RouteName {
  TREATMENT_RESERVATION = 'TreatmentReservation',
  TREATMENT_RESERVATION_COMPLETE = 'TreatmentReservationComplete',
  PRIVACY_POLICY = 'PrivacyPolicy',
  TERMS_OF_SERVICE = 'TermsOfService',
  ERROR = 'Error'
}

const routes: Array<RouteConfig> = [
  {
    path: '*',
    redirect: '/404',
    meta: { hidden: true }
  },
  {
    path: '/404',
    component: () =>
      import(/* webpackChunkName: '404' */ '@/views/error/404.vue'),
    meta: { hidden: true }
  },
  {
    path: '/error',
    component: ManagePage,
    children: [
      {
        path: '',
        component: () => import('@/views/error/ErrorPage.vue'),
        props: (route: Route) => ({ error: route.params.error }),
        name: RouteName.ERROR
      }
    ]
  },
  {
    path: '/reservation/:medicalBusinessId',
    component: ManagePage,
    props: (route: Route) => ({ medicalBusinessId: route.params.medicalBusinessId }),
    children: [
      {
        path: '',
        component: () => import('@/views/treatment-reservation/TreatmentReservationPage.vue'),
        name: RouteName.TREATMENT_RESERVATION
      },
      {
        path: 'complete',
        component: () => import('@/views/treatment-reservation/TreatmentReservationCompletePage.vue'),
        name: RouteName.TREATMENT_RESERVATION_COMPLETE
      }
    ]
  },
  {
    path: '/reservation/:medicalBusinessId/privacy-policy',
    component: ManagePage,
    props: (route: Route) => ({ medicalBusinessId: route.params.medicalBusinessId }),
    children: [
      {
        path: '',
        component: () => import('@/views/privacy-policy/PrivacyPolicyPage.vue'),
        name: RouteName.PRIVACY_POLICY
      }
    ]
  },
  {
    path: '/reservation/:medicalBusinessId/terms-of-service',
    component: ManagePage,
    props: (route: Route) => ({ medicalBusinessId: route.params.medicalBusinessId }),
    children: [
      {
        path: '',
        component: () => import('@/views/terms-of-service/TermsOfServicePage.vue'),
        name: RouteName.TERMS_OF_SERVICE
      }
    ]
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
