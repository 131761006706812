import { render, staticRenderFns } from "./ManagePage.vue?vue&type=template&id=078e7529&scoped=true&"
import script from "./ManagePage.vue?vue&type=script&lang=ts&"
export * from "./ManagePage.vue?vue&type=script&lang=ts&"
import style0 from "./ManagePage.vue?vue&type=style&index=0&id=078e7529&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "078e7529",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VSnackbar})
