export const isValidUsername = (str: string) => ['admin', 'editor'].indexOf(str.trim()) >= 0

export const isExternal = (path: string) => /^(https?:|mailto:|tel:)/.test(path)

export const isArray = (arg: any) => {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}

export const isURL = (url: string) => {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(url)
}

export const isValidailMailAddress = (mailaddress: string) => {
  const reg = /^[-+.\w]+@[-a-zA-Z0-9]+(\.[-a-zA-Z0-9]+)*\.[a-zA-Z]{2,19}$/
  return reg.test(mailaddress)
}

export const isValidateNumber = (value: string) => {
  const reg = /^[０-９0-9]*$/
  return reg.test(value)
}

export const isKana = (value: string) => {
  const reg = /^[ァ-ヶー]*$/
  return reg.test(value)
}

export const isCellPhoneNumber = (value: string) => {
  const reg = /0[5789]0\d{8}$/
  return reg.test(value)
}

export const isPhoneNumber = (value: string) => {
  const reg = /^[０-９0-9]*$/
  return reg.test(value)
}

export const isPostalCode = (value: string) => {
  const reg = /^[0-9]{7}$/
  return reg.test(value)
}

export const isUuid = (value: string) :boolean => {
  const reg = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/
  return reg.test(value)
}
