import Vue from 'vue'
import Vuex from 'vuex'
import { IAlertState } from './modules/alert'

Vue.use(Vuex)

export interface IRootState {
  alert: IAlertState
}

export default new Vuex.Store<IRootState>({})
